import React from 'react';
import AdminUploadProject from './Uploads/ProjectsUpload';
import AccountsPayment from '../../Accountscomponent/AccountsPayment';
const AccountsDashboard = () => (
  <>
    <main className='main-content'>
      <div className=''>
      <AccountsPayment />
      </div>
      
    </main>
  </>
);
export default AccountsDashboard;